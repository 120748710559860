import React, { useState, useEffect, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { Spinner } from 'reactstrap';


export default function ExportQR(props) {
  const [exporting, setExporting] = useState(false);
  const ref = useRef();
  const save = () => {
    setExporting(true)
    setTimeout(() => {
      const canvas = ref.current.children[0];
      let a = document.createElement('a')
      a.href = canvas.toDataURL()
      a.download = props.name ?? 'qr-' + Math.random().toString(16).slice(2);
      a.click()
      setExporting(false)
    }, 1000)
  }
  return (
    <>
      {exporting ?
        <span className='clickable icofont mx-1'><Spinner size='sm' /></span>
        :
        <span className='clickable icofont mx-1' onClick={save}>&#xefc8;</span>
      }
      {exporting &&
        <div ref={ref} style={{ display: 'none' }}>
          <QRCodeCanvas value={props.url} size={128} level={'H'} />
        </div>
      }
    </>
  );
}


import React, { useEffect, useState } from "react";
import { Button, Form, Input, InputGroup, InputGroupText, Spinner } from "reactstrap";
import { ApiService } from "../services/apiService";


export const EditLink = ({ row, success }) => {
    const [updatedRow, setUpdatedRow] = useState({ ...row });
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        new ApiService().put('l', updatedRow)
            .then(p => p?.id && success && success())
            .catch(() => alert('Something went wrong'))
            .finally(() => setLoading(false));
    }
    useEffect(() => {
        new ApiService().get('l/categories').then(setCategories)
    }, []);
    useEffect(() => setUpdatedRow({ ...row }), [row]);
    const set = (key, e) => {
        setUpdatedRow(o => ({ ...o, [key]: e.target.value }));
    }
    if (loading)
        return <div style={{ display: 'flex', justifyContent: 'center' }}><Spinner /></div>
    return (
        <Form onSubmit={handleSubmit}>
            <InputGroup className="mb-2">
                <InputGroupText>Name</InputGroupText>
                <Input required value={updatedRow?.name} onChange={(e) => set('name', e)} />
            </InputGroup>
            <InputGroup className="mb-2">
                <InputGroupText>Url</InputGroupText>
                <Input value={updatedRow?.url} onChange={(e) => set('url', e)} />
            </InputGroup>
            <InputGroup className="mb-2">
                <InputGroupText>Category</InputGroupText>
                <Input type="select" value={updatedRow?.shortUrlCategoryId ?? -666} onChange={(e) => set('shortUrlCategoryId', e)}>
                    {categories.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                </Input>
            </InputGroup>
            {row.sent && <small style={{ color: 'grey' }}>This row has already been sent. Updating it will change status to pending so it's sent out again.</small>}
            <Button className="w-100 mt-3" type="submit">Save</Button>
        </Form>
    );
};

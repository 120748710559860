import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import { Button, Input, InputGroup, InputGroupText, Table, Spinner} from 'reactstrap';
import { ApiService } from '../services/apiService';
import { niceDate } from '../services/utility';

export const Logs = () => {
    const [logs, setLogs] = useState([]);
    const [pagination, setPagination] = useState();
    const rows = pagination?.data ?? [];
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [query2, setQuery2] = useState('');
    const navigate = useNavigate();

    const [pageSize, setPageSize] = useState(20);

    useEffect(() => {
        fetchRows();
    }, [page, pageSize]);

    const fetchRows = () => {
        setLoading(true);
        new ApiService()
            .get(`utility/logs/?pageSize=${pageSize}`)
            .finally(() => setLoading(false))
            .then((r) => {
                console.log(r);
                setPagination(r);
            });
    };
    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
    };

    
 
    if (loading)
        return <div className='spinner-center'><Spinner/></div>

    return (
        
        <div
            style={{
                backgroundColor: 'white',
                padding: '1em',
                borderRadius: '5px',
            }}>
            <h4>Information Logs</h4>           
            <div>
                <InputGroup className="py-3 w-25">
                    <InputGroupText className='icofont'>&#xed12;</InputGroupText>
                    <Input placeholder='Search...' value={query} onChange={e => setQuery(e.target.value)} />
                    <Button color={query ? 'danger' : 'light'} className='icofont'
                        onClick={() => setQuery('')}>&#xeee4;</Button>
                </InputGroup>
                <div>

                    <label htmlFor='pageSize'
                        style={{ marginRight: '0.5rem', marginBottom: '0.5rem', verticalAlign: 'middle' }}>Rows per
                        page:</label>
                    <select
                        id='pageSize'
                        style={{ marginBottom: '0.5rem', verticalAlign: 'middle' }}
                        value={pageSize}
                        onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}
                    >
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                    </select>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th style={{ width: '5%' }}>Type</th>
                            <th style={{ width: '15%' }}>Time</th>
                            <th style={{ width: '50%' }}>Message</th>
                            <th style={{ width: '30%' }} className='text-center'>Details</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ? <tr style={{ textAlign: 'center', backgroundColor: 'transparent' }}>
                                <td colSpan={100}><Spinner color='primary' /></td>
                            </tr>
                                :
                                rows.map(r =>
                                    <tr key={r.id} className='tr-td-vc'>
                                        <td>{r.logType}</td>
                                        <td>{niceDate(r.logTime)}</td>
                                        <td>{r.message}</td>
                                        <td>{r.details}</td>
                                    </tr>
                                )}
                    </tbody>
                </Table>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', textAlign: 'center' }}>
                    <span>Total: {pagination?.count ?? 0} rows</span>
                    <span>Page {page} / {pagination?.totalPages ?? 1}</span>
                    <span>
                        <Button onClick={() => setPage(p => p - 1)} disabled={page <= 1} color='outline-primary'
                            style={{ marginRight: '1rem' }} className='icofont'>&#xea9d;</Button>
                        <Button onClick={() => setPage(p => p + 1)} disabled={page >= pagination?.totalPages ?? 1}
                            color='outline-primary' style={{ marginRight: '1rem' }} className='icofont'>&#xeaa0;</Button>
                    </span>
                </div>

            </div>
        </div>
    );
}
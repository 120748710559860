import { useState } from "react";

// interface Modalias {
//   closeModal: () => void;
//   showModal: () => void;
//   show: boolean;
//   item? : Type;
//   setItem: (item : Type) => void;
// }
// interface Props{
//   onClose? : (needRefetch) => void;
// }

export function useHandleModal(props) {
  
  const [show, setShow] = useState(false);
  const [item, setItem] = useState();
  const closeModal = (needRefetch) => {
    setShow(false); 
    props?.onClose && props.onClose(needRefetch);
  }
  const showModal = () => setShow(true);
  return {
    closeModal: closeModal,
    showModal: showModal,
    show: show,
    item: item,
    setItem: setItem,
  };
}
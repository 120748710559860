import React, { Component } from 'react';
import {Collapse, Navbar, NavbarBrand, NavbarToggler} from 'reactstrap';
import {Link} from 'react-router-dom';
import {LoginMenu} from './api-authorization/LoginMenu';
import './NavMenu.scss';

export class NavMenu extends Component {
    static displayName = NavMenu.name;
    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };        
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {       
        if (this.props.auth) {
            return (
                <header>
                    <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-4" container light>
                        <NavbarBrand tag={Link} to="/">
                            <img style={{ height: '3.75rem' }} className="img-responsive" src="logo.png" alt="logo" />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse pt-1" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <LoginMenu>
                                </LoginMenu>
                            </ul>
                        </Collapse>
                    </Navbar>
                </header>
            );
        }
        return <div></div>;
    }
}

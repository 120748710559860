
import React, { useEffect, useState } from "react";
import { Button, Input, InputGroup, InputGroupText, Spinner } from "reactstrap";
import { useHandleModal } from "../hooks/useHandleModal";
import { ApiService } from "../services/apiService";
import { exportArraysToCSV, exportArraysToJSON, sanitize } from "../services/utility";
import { InfoModal } from "./InfoModal";

export const ExportProject = ({ project }) => {
    const [loading, setLoading] = useState(false);
    const modal = useHandleModal();
    const columns = [
        ...(project?.columns ?? []),
        { id: -1, name: 'send date' },
        { id: -2, name: 'phone' },
        { id: -3, name: 'email' },
        { id: -4, name: 'recordId' },
        { id: -5, name: 'video name', defaultValue: 'unchecked' },
        { id: -6, name: 'video link', defaultValue: 'unchecked' },
        { id: -7, name: 'video link short' },
        { id: -8, name: 'clicks', defaultValue: 'unchecked' },
        { id: -9, name: 'created', defaultValue: 'unchecked' },
        { id: -10, name: 'thumbnail image', defaultValue: 'unchecked' },
        { id: -11, name: 'cta clicks' },
        { id: -12, name: 'play clicks' },
        { id: -13, name: 'email template', defaultValue: 'unchecked' },
    ];
    const [selectedColumns, setSelectedColumns] = useState([]);

    const resetSelectedColumns = () => setSelectedColumns(columns.filter(c => !c.defaultValue).map(c => c.id));
    useEffect(() => resetSelectedColumns(), [project?.columns]);

    const openModal = () => {
        modal.setItem(project);
        modal.showModal();
        resetSelectedColumns();
    }

    const selectAll = () => setSelectedColumns(o => o.length ? [] : columns.map(c => c.id))

    const toggleColumn = (id) => {
        setSelectedColumns(o => o.includes(id) ? o.filter(ci => ci != id) : [...o, id]);
    }

    const exportProjectCSV = () => {
        setLoading(true);
        new ApiService()
            .post('project/export', { id: project.id, columnIds: selectedColumns })
            .then(r => {
                if (r) {
                    exportArraysToCSV([r.headers, ...r.rows], sanitize(project.name ?? 'result') + ".csv");
                    modal.closeModal();
                }
            })
            .finally(() => setLoading(false));
    }

    const exportProjectJSON = () => {
        setLoading(true);
        new ApiService()
            .post('project/export', { id: project.id, columnIds: selectedColumns })
            .then(r => {
                if (r) {
                    exportArraysToJSON(r.headers, r.rows, sanitize(project.name ?? 'result') + ".json");
                    modal.closeModal();
                }
            })
            .finally(() => setLoading(false));
    }

    const modalBody = () => {
        return (
            <div>
                <div>
                    <small>Select Columns to Export</small>
                    <Button color={selectedColumns.length === 0 ? 'light' : 'primary'}
                        className="m-1" style={{ float: 'right', width: '2rem', height: '2rem' }} onClick={selectAll}></Button>
                </div>
                <div>
                    {columns.map((c, i) =>
                        <InputGroup key={i} className="mb-2">
                            <InputGroupText className="w-75">{c.name}</InputGroupText>
                            <Input type='checkbox' className="w-25"
                                checked={selectedColumns.includes(c.id)}
                                onChange={() => toggleColumn(c.id)}
                            />
                        </InputGroup>
                    )}
                </div>
            </div>
        );
    }

    return (
        <>
            <Button color='outline-primary' className='me-2' size='sm' onClick={openModal}>
                <small className='icofont pe-1'>&#xef08;</small> Export
            </Button>

            <InfoModal
                handleClose={modal.closeModal}
                show={modal.show}
                headerText={"Export " + (project?.name ?? '')}
                body={loading ? <Spinner size={'lg'} className="d-block m-auto" /> : modalBody()}
                footer={<div className="row w-100"><Button className="col-5" onClick={exportProjectJSON}>Export (json)</Button><div className="col-2">&nbsp;</div>
                    <Button className="col-5" onClick={exportProjectCSV}>Export (csv)</Button></div>}
            />
        </>
    );
};

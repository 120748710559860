import React from "react";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";

export const InfoModal = ({
                              handleClose, show, headerText, headerIcon, body, footer, size
                          }) => {

    return (
        <Modal isOpen={show} toggle={() => handleClose(false)} centered size={size}>
            <ModalHeader className="border-0 pb-0 mt-2 fw-bold">
                {/* {headerIcon && <FontAwesomeIcon icon={headerIcon} width="16" className="me-2" />} */}
                {headerText}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={12} xl={12}>
                        {body}
                    </Col>
                </Row>
            </ModalBody>
            {footer &&
                <ModalFooter>
                    {footer}
                </ModalFooter>}
        </Modal>
    );
};

import React from 'react';
import { CreateProject } from '../components/CreateProject';

export const NewProject = () => {
  return (
    <div
        style={{
            backgroundColor: 'white',
            padding: '1em',
            borderRadius: '5px',
        }}>
      <h4>Create New Project</h4>
      <CreateProject />
    </div>
  );
}
import './public.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Spinner, Toast, ToastBody } from 'reactstrap';
import { ApiService } from '../../services/apiService';

export const HubSpotInstall = (props) => {
    const [status, setStatus] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [toastOpacity, setToastOpacity] = useState(0);
    let [params] = useSearchParams();

    useEffect(() => {
        setLoading(true);
        var code = params.get("code");
        new ApiService().get('public/hubspot/install?code=' + code)
            .then(r => {
                if (r === true) setStatus(true)
                else setStatus(false)
            })
            .catch(r => console.error(r))
            .finally(() => setLoading(false));
    }, [params])

    if (loading)
        return <div><div className='spinner-center'><Spinner /></div></div>

  return (
    <div >
          <div className="center-container">
              <div style={{
                  minHeight: '50px',
                  minWidth: '600px',
                  paddingBottom: '2rem',
                  background: 'url("hubspot.png")',
                  zIndex: 1,
                  backgroundBlendMode: 'multiply',
                  backgroundColor: 'rgba(253, 189, 0, 0)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
              }}></div>
              <br />
              <div style={{ display: status ? '' : 'none', paddingLeft: '1rem' }}>Hubspot Install Completed Successfully.</div>
              <div style={{ display: status ? 'none' : '', paddingLeft: '1rem' }}>Hubspot Install Failed. <br /> { message}</div>
          </div>

          <Toast style={{ maxWidth: '90vw', position: 'fixed', bottom: '0.5rem', left: '50vw', opacity: toastOpacity, pointerEvents: 'none', transform: 'translateX(-50%)' }}
            className="p-1 bg-success text-light">
            <ToastBody>
              Successfully Added!
            </ToastBody>
          </Toast>
    </div>
  );
}
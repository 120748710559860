import React, { useEffect, useRef, useState } from 'react';
import { Input, InputGroup, Label, Button, Spinner, InputGroupText } from 'reactstrap';
import { ApiService } from '../../services/apiService';
import { FileDrop } from '../../components/FileDrop';
import { useToast } from '../../hooks/useToast';

export const FileUpload = () => {
  const [loading, setLoading] = useState();
  const [key, setKey] = useState('');
  const toast = useToast()
  useEffect(() => {
    setKey(localStorage.getItem('keykeykey') ?? '')
  }, [])
  const submitFile = (file) => {
    setLoading(true);
    localStorage.setItem('keykeykey', key)
    new ApiService()
      .postWithFiles(`utility/files`, { file, key })
      .then(r => r.json())
      .then(r => {
        if (r) {
          toast.activate({ text: 'Filen är uppladdad!', type: 'SUCCESS' })
        }
        else {
          toast.activate({ text: 'ERROR: Fel nyckel eller dålig fil.', type: 'DANGER' });
        }
      })
      .catch(() => toast.activate({ text: 'ERROR: Fel nyckel eller dålig fil eller något annat som krånglar.', type: 'DANGER' }))
      .finally(() => setLoading(false));
  }
  return (
    <div className='background'>
      <div className='file-upload'>
        <div>
          <img style={{ width: '20rem' }} src="weqlogo.png" alt="logo" />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <InputGroup style={{ width: '11rem' }}>
              <InputGroupText className='icofont'>&#xef7a;</InputGroupText>
              <Input
                type='password'
                placeholder='Nyckel'
                value={key}
                onChange={(e) => setKey(e.target.value)}
              />
            </InputGroup>
          </div>
          <div style={{ minWidth: '50vw', height: '50vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {loading ?
              <Spinner />
              :
              <FileDrop disabled={key?.length !== 10} onFileSelected={submitFile} />
            }
          </div>
        </div>
      </div>
      {toast.html}
    </div>
  );
}
import { useRef, useState } from "react";

export const useToast = () =>  {
  const [opacity, setOpacity] = useState(0);
  const intervalHandler = useRef(0);
  const [options, setOptions] = useState({})

  const activate = (options) => {
    setOptions(options)
    const fun = () => {
      setOpacity(o => {
        if (o <= 0) clearInterval(intervalHandler.current)
        return o - (o > 0.7? 0.002 : 0.02)
      })
    }
    setOpacity(1)
    clearInterval(intervalHandler.current)
    intervalHandler.current = setInterval(fun, 10);
  }
  const backgroundColors = {SUCCESS: '#5e5', WARNING: 'orange', DANGER: 'crimson'}
  const backgroundColor = backgroundColors[options?.type ?? 'SUCCESS']
  const htmls = () => (
    <div style={{position: 'fixed', width: '100vw', textAlign: 'center', padding: '0.5rem', fontSize: '1.25rem',  opacity, backgroundColor, ...(options?.position === 'TOP' ? { top: 0 } : { bottom: 0 }) }}>
      <div style={{color: 'white'}}>{options?.text}</div>
    </div>
  )
  return {
    html: htmls(),
    activate
  };
}
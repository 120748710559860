import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, InputGroup, InputGroupText, Col, Row, FormText, Button, Spinner, Table } from 'reactstrap';
import { ApiService } from '../services/apiService';

export const FilesList = (props) => {
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    setLoading(true)
    refetch()
  }, []);
  const refetch = () => {
    new ApiService().get('utility/files?path=').then(l => l && setLinks(l))
      .finally(() => setLoading(false));
  }
	const download = () => {
		new ApiService().getBlob('utility/download-files').then(r => {
			let csvFile;
			let downloadLink;
			csvFile = new Blob([r], { type: 'application/octet-stream' });
			downloadLink = document.createElement("a");
			downloadLink.download = "files.zip";
			downloadLink.href = window.URL.createObjectURL(csvFile);
			downloadLink.click();
		});
	}

  const remove = (name) => {
    if (!window.confirm('Are you sure you want to delete the link?'))
      return;
    setLoading(true)
    new ApiService().delete('utility/files?filename=' + name)
      .catch(() => alert('Something went wrong'))
      .then(l => {
        if (l)
          setLinks(o => o.filter(f => f !== name))
      }).finally(() => setLoading(false));
  }
  return (
    <div>
      <div style={{ float: 'right', margin: '1rem' }}>
        <div>
          <div style={{display: 'inline-block', marginRight: '2rem'}}>
            <div>
              <a href='guest/upload' target='_blank'><u>Link to upload form</u></a>
            </div>
            <code>
              Nyckel: weq2023!j@
            </code>
          </div>
          <Button color='outline-primary' onClick={download}>Download</Button>
        </div>
      </div>
      {loading ?
        <Spinner></Spinner>
        :
        <Table style={{ backgroundColor: 'black', color: '#F39200' }}>
          <thead>
            <tr>
              <th>Name</th>
              <th className='text-center'>Action</th>
            </tr>
          </thead>
          <tbody>
            {links?.map(r =>
              <tr key={r} className='tr-td-vc'>
                <td>{r}</td>
                <td className="text-center">
                  <span className='clickable icofont mx-1' onClick={() => remove(r)}>&#xee09;</span>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      }
    </div>
  );
}
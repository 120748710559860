import React, {useEffect, useState} from "react";
import {Spinner} from "reactstrap";
import {ApiService} from "../services/apiService";


export const Stats = () => {
    const [loading, setLoading] = useState(false);
    const [stats, setStats] = useState();
    useEffect(() => {
        setLoading(true);
        new ApiService().get('utility/stats')
            .then(r => r && setStats(r))
            .finally(() => setLoading(false));
    }, [setLoading])

    return (
        <div className="d-none d-sm-flex"
            // style={{position: 'fixed', top: '50vh', right: '10vw', transform: 'translateY(-50%)'}}
        >
            <div style={{
                animation: 'shake 0.5s',
                display: 'flex',
                width: '15rem',
                height: '30rem',
                backgroundColor: '#000',
                overflow: 'hidden',
                border: '10px solid black',
                borderRadius: '20px'
            }}>
                {/* <div style={{ position: 'absolute', top: '-5rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginLeft: '-10px' }}>
                    <div><small><small><small>Short Urls Remaining: {stats?.urlCredits}</small></small></small></div>
                    <div style={{ marginBottom: '1rem' }}><small>Bulk Gate Credits: <span style={(stats?.smsCredits ?? 1000) < 1000 ? { color: 'red' } : {}}>{stats?.smsCredits}</span></small></div>
                    <img width="150px" src="logo.png" alt="logo" />
                </div> */}
                <div style={{flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {loading ? <Spinner color='secondary'/>
                        :
                        <div style={{textAlign: 'center', color: 'white'}}>
                            <div title='Videos created synced with Vimeo' style={{
                                backgroundColor: '#000',
                                padding: '1rem',
                                paddingTop: '0rem',
                                borderRadius: '15px',
                                margin: '0.5rem'
                            }}>
                                <h2 style={{margin: 0, color: '#FDBD00'}}><b>{stats?.videos ?? '-'}</b></h2>
                                <h5 style={{margin: 0}}>VIDEOS</h5>
                            </div>
                            <div title='Total clicks for all projects' style={{
                                backgroundColor: '#000',
                                padding: '1rem',
                                paddingTop: '0rem',
                                borderRadius: '15px',
                                margin: '0.5rem'
                            }}>
                                <h2 style={{margin: 0, color: '#FDBD00'}}><b>{stats?.clicks ?? '-'}</b></h2>
                                <h5 style={{margin: 0}}>CLICKS</h5>
                            </div>
                            <div title='Total CTA clicks' style={{
                                backgroundColor: '#000',
                                padding: '1rem',
                                paddingTop: '0rem',
                                borderRadius: '15px',
                                margin: '0.5rem'
                            }}>
                                <h2 style={{margin: 0, color: '#FDBD00'}}><b>{stats?.ctaClicks ?? '-'}</b></h2>
                                <h5 style={{margin: 0}}>CTA CLICKS</h5>
                            </div>
                            <div title='Percentage of clicked videos (only counting videos delivered by sms)' style={{
                                backgroundColor: '#000',
                                padding: '1rem',
                                paddingTop: '0rem',
                                borderRadius: '15px',
                                margin: '0.5rem'
                            }}>
                                <h1 style={{margin: 0, color: '#FDBD00'}}><b>{stats?.clickRate ?? '- '}%</b></h1>
                                <h5 style={{margin: 0}}>CLICK RATE</h5>
                            </div>
                            <div></div>
                            <div style={{color: '#fff'}}><small><small><small>Short Url
                                Credits: {stats?.urlCredits}</small></small></small></div>
                            <div style={{color: '#fff'}}><small>Bulk Gate Credits: <span
                                style={(stats?.smsCredits ?? 1000) < 1000 ? {
                                    color: 'crimson',
                                    fontWeight: 'bold'
                                } : {}}>{stats?.smsCredits}</span></small></div>

                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

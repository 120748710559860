
import React, { useRef, useState } from "react";

export const FileDrop = (props) => {
    const fileInputRef = useRef()
    const [hovering, setHovering] = useState(false);
    function dropHandler(ev) {
        ev.preventDefault(); // Prevent file from being opened
        let files = []
        if (ev.dataTransfer.items) {
            [...(ev.dataTransfer.items)]
                .filter(f => f.kind === "file")
                .forEach(item => files[files.length] = item.getAsFile());
        } else[...ev.dataTransfer.files].forEach(file => files[files.length] = file.name);
        // console.log(files)
        props.onFileSelected(files[0])
    }
    const style = { backgroundColor: '#222', width: '100%', height: '100%', border: '2px solid transparent', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', userSelect: 'none', cursor: 'pointer' }
    if (props.disabled) return <div style={{ ...style, cursor: 'auto', borderColor: 'transparent' }}><div><span className="icofont" style={{ fontSize: '3rem', color: 'grey' }}>&#xef7a;</span></div></div>
    return (
        <div
            onDrop={dropHandler}
            onDragOver={e => {e.preventDefault();setHovering(true);}}
            onDragEnter={() => setHovering(true)}
            onDragLeave={() => setHovering(false) }
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false) }
            style={{ ...style, ...(hovering ? { color: '#5d5', borderColor: '#5d5' } : {borderColor: 'transparent'}) }}
            onClick={() => fileInputRef.current.click()}
        >
            <input
                style={{ display: 'none' }}
                type="file"
                ref={fileInputRef}
                onChange={e => props.onFileSelected(e.target.files[0])}
            />
            <p>DRAG 'N DROP</p>
            <p>(eller klicka här)</p>
            <p></p>
            <p></p>
            <span className="icofont" style={{ fontSize: '4rem' }}>&#xf01c;</span>
        </div>
    )
};

import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from "react-player";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {PlayIcon} from "../components/PlayIcon"

const VideoPlayer = () => {
    const playerRef = useRef(null);
    const { width, height } = useWindowDimensions();
    const [playing, setPlaying] = useState(false);
    const [videoWidth, setVideoWidth] = useState(0);
    const [videoHeight, setVideoHeight] = useState(0);

    const params = new URLSearchParams(window.location.search);
    const url = params.get('url');
    const img = params.get('img') ?? true;

    useEffect(() => {
        setPlaying(true);
    }, []);

    // Adjusting videoWidth and videoHeight based on aspect ratio
    useEffect(() => {
        if (width <= 768) {
            if (window.matchMedia("(orientation: portrait)").matches) {
                // Height is greater than the width in portrait mode
                setVideoWidth(width);
                setVideoHeight(height);
            } else {
                // Width is greater than the height in landscape mode
                setVideoWidth(height);
                setVideoHeight(width);
            }
        } else {
            const videoWidth = Math.min(width, height * 16 / 9);
            const videoHeight = videoWidth * 9 / 16;
            setVideoHeight(videoHeight)
            setVideoWidth(videoWidth)
        }

    }, [width, height]);

    return (
        <div className={"d-flex justify-content-center align-items-start"}
            style={{
                backgroundColor: "#393939",
                // height: "100vh",
            }}>
            <div style={{
                backgroundColor: "black",
            }}>
                <ReactPlayer
                    url={url}
                    controls={true}
                    playing={playing}
                    light={img}
                    pip={true}
                    stopOnUnmount={false}
                    ref={playerRef}
                    width={videoWidth}
                    height={videoHeight}
                    playIcon={<PlayIcon />}
                />
            </div>
        </div>
    );
};

export default VideoPlayer;

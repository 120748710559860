import authService from '../components/api-authorization/AuthorizeService'
import axios from "axios";

const Axios = axios.create({
    timeout: 10000 * 60 * 60 * 24,
});

export class ApiService {
    isJSON = (r) => r?.headers?.get("content-type") && r.headers.get("content-type").indexOf("application/json") !== -1;
    get = async (end) => {
        const token = await authService.getAccessToken();
        return await fetch(end, {
            headers: !token ? {} : {'Authorization': `Bearer ${token}`}
        }).then(r => this.isJSON(r) ? r.json() : r);
    }
    getBlob = async (end) => {
        const token = await authService.getAccessToken();
        return await fetch(end, {
            headers: !token ? {} : {'Authorization': `Bearer ${token}`}
        }).then(r => r.blob());
    }
    delete = async (end, data) => {
        const token = await authService.getAccessToken();
        const options = {
            headers: {
                ...(token && {'Authorization': `Bearer ${token}`}),
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify(data)
        }
        return await fetch(end, options).then(r => this.isJSON(r) ? r.json() : r);
    }
    postWithFiles = async (end, data) => {
        let formData = new FormData();
        Object.keys(data).forEach(k => formData.append(k, data[k]))

        const token = await authService.getAccessToken();
        const options = {
            headers: {
                ...(token && {'Authorization': `Bearer ${token}`}),
                // 'Content-Type': 'multipart/form-data'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            method: 'POST',
            body: formData
            // body: JSON.stringify(data)
        }
        return await fetch(end, options)
        //.then(response => response.json())
    }
    postOctetStream = async (url, arrayBuffer) => {
        const token = await authService.getAccessToken();
        const options = {
            headers: {
                ...(token && {'Authorization': `Bearer ${token}`}),
                'Content-Type': 'application/octet-stream'
            },
            secure: true,
        };
        return Axios.post(url, arrayBuffer, options);
    }
    post = async (end, data) => {
        const token = await authService.getAccessToken();
        const options = {
            headers: {
                ...(token && {'Authorization': `Bearer ${token}`}),
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            method: 'POST',
            body: JSON.stringify(data)
        }
        return await fetch(end, options)
            .then(r => this.isJSON(r) ? r.json() : r);
    }
    put = async (end, data) => {
        const token = await authService.getAccessToken();
        const options = {
            headers: {
                ...(token && {'Authorization': `Bearer ${token}`}),
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(data)
        }
        return await fetch(end, options)
            .then(r => this.isJSON(r) ? r.json() : r);
    }
}

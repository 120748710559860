import './BildeveRowForm.scss';
import React, { useEffect, useState } from 'react';
import { Form, Input, InputGroup, Button, Label, ButtonGroup } from 'reactstrap';
import { capitalizeWords } from '../../../services/utility';
import { useSearchParams } from 'react-router-dom';

export const BildeveRowForm = ({ template, values, set, setMany, handleSubmit, formRef }) => {
  // const [showDefaults] = useState(false);
  let [params] = useSearchParams();
  const [t, setT] = useState('text')

  // Initialize city and technician
  useEffect(() => {
    if (values.city)
      return;
    setMany({
      city: params.get('city') ?? 'Helsingborg',
      technician: capitalizeWords(params.get('technician')?.replace('_', ' ') ?? 'jane doe'),
      technicianPhone: params.get('phone'),
      // datum: (new Date()).toISOString().substring(0, 10),
      // tid: '08:00',
    })
  }, [values, setMany, params])

  // Fetch car info from regnr api
  // useEffect(() => {
  //   if (values.regnr?.length !== 6)
  //     return;

  //   setMany({ car: 'volvo 600' })
  // }, [values.regnr, setMany])

  const magicalTransformation = () => {
    if(t !== 'datetime-local'){
      setT('datetime-local')
      document.getElementById('ttt').click();
    }
  }

  // Set send date 24 hr before service date and prettify date/time of service
  useEffect(() => {
    if (!values.servicedate)
      return;
    const serviceDate = new Date(values.servicedate)
    let SendDate = new Date(serviceDate.getTime() - (24 * 60 * 60 * 1000)).toISOString();
    if (SendDate === values.SendDate)
      return;
    const dayName = serviceDate.toLocaleDateString('sv-SE', { weekday: 'long' });
    const dateName = serviceDate.toLocaleDateString('sv-SE', { day: 'numeric', month: 'long' });
    const timeName = serviceDate.toLocaleString('sv-SE', { hour: '2-digit', minute: '2-digit' });
    const servicetime = `${dayName} den ${dateName} klockan ${timeName}`; // 'måndag den 14 maj klockan 16:00'
    setMany({ SendDate, servicetime, })
  }, [values.servicedate, setMany])

  return (
    <div className='BildeveRowForm'>
      <Form ref={formRef} onSubmit={handleSubmit} autoComplete="off">
        <InputGroup style={{ justifyContent: 'center' }}>
          <Input className='regnr' pattern=".{6}" maxLength={6} value={values['regnr'] ?? ''} required placeholder='ABC123' onChange={e => set('regnr', e, a => a.toUpperCase())} />
        </InputGroup>
        <InputGroup>
          <Input value={values['car'] ?? ''} required placeholder='BILMODELL' onChange={e => set('car', e)} />
        </InputGroup>
        <InputGroup>
          <Input value={values['firstname'] ?? ''} required placeholder='KUNDENS FÖRNAMN' onChange={e => set('firstname', e)} />
        </InputGroup>
        <InputGroup>
          <Input value={values['Phone'] ?? ''} required type="tel" placeholder='KUNDENS MOBILNUMMER' onChange={e => set('Phone', e)} />
        </InputGroup>
        <InputGroup>
            <Input value={values['city'] ?? 'Helsingborg'} type="text" placeholder='Helsingborg' onChange={e => set('city', e)} />
        </InputGroup>
        <InputGroup>
          <Input
            id='ttt'
            type={values['servicedate']? 'datetime-local' : t}
            onFocus={magicalTransformation}
            placeholder='DATUM FÖR SERVICE'
            required
            value={values['servicedate'] ?? ''}
            onChange={e => set('servicedate', e)} />
        </InputGroup>
        {/* <InputGroup>
          <Input type='date'
            required
            value={values['datum'] ?? new Date()}
            onChange={e => set('datum', e)} />
          <Input type='time'
            required
            value={values['tid'] ?? new Date()}
            onChange={e => set('tid', e)} />
        </InputGroup> */}
        {/* <ButtonGroup style={{ flexDirection: 'column' }}>
          <Label>
            <Button className='check' color='outline-primary' onClick={e => set('city', e)} value='Helsingborg' active={values['city'] === 'Helsingborg'}></Button>
            HELSINGBORG
          </Label>
          <Label style={{ color: 'grey' }}>
            <Button disabled className='check' onClick={e => set('city', e)} value='Landskrona' active={values['city'] === 'Landskrona'}></Button>
            LANDSKRONA
          </Label>
          <Label style={{ color: 'grey' }}>
            <Button disabled className='check' onClick={e => set('city', e)} value='Höganäs' active={values['city'] === 'Höganäs'}></Button>
            HÖGANÄS
          </Label>
        </ButtonGroup> */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button className='mt-3 submittt' type="submit">KLART</Button>
        </div>
      </Form>
    </div>
  );
}
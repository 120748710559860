import React, {useEffect, useState} from 'react';
import Uppy from "@uppy/core";
import DropTarget from "@uppy/drop-target";
import XHR from "@uppy/xhr-upload";
import {Dashboard} from "@uppy/react";
import useWindowDimensions from "../hooks/useWindowDimensions";

const MultiUpload = ({
                         project,
                         refetchProject
                     }) => {
    const {width, height} = useWindowDimensions();
    const [endPoint, setEndPoint] = useState('https://weq.se/files/upload');

    useEffect(() => {
        if (project && project?.id) {
            const endpointPieces = window.location.href.split('/');
            const endpoint = `${endpointPieces[0]}//${endpointPieces[2]}/files/multi-upload?projectId=${project.id}`;
            setEndPoint(endpoint);
        }
    }, [project]);

    const uppy = React.useMemo(() => {
        return new Uppy({
            restrictions: {
                maxFileSize: 1000 * 1024 * 1024,
                allowedFileTypes: ['video/*'],
            }
        })
            .use(DropTarget, {
                target: document.body,
            })
            .use(XHR, {
                endpoint: endPoint,
                validateStatus: function (status) {
                    return status >= 200 && status < 300;
                }
            });
    }, [endPoint])

    return (
        <div>
            <Dashboard
                uppy={uppy}
                target={'#drag-drop-area'}
                proudlyDisplayPoweredByUppy={false}
                showProgressDetails={true}
                width={width}
                height={height - 400}
            />
        </div>
    );
};

export default MultiUpload;
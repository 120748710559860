import React, { Component } from 'react';
import {Route, Routes} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import {Layout} from './components/Layout';
import {Spinner} from 'reactstrap';
import {ApiService} from './services/apiService';
import { RedirectAsd } from './components/RedirectAsd';

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {ready: false, authenticated: false};
    }

    // componentDidMount() {
    //   this._subscription = authService.subscribe(() => {
    //     this.setState({ ready: false, authenticated: false });
    //     this.checkAuth();
    //   });
    //   this.checkAuth();
    // }
    // componentWillUnmount() {
    //   authService.unsubscribe(this._subscription);
    // }
    // async checkAuth() {
    //   const authenticated = await authService.isAuthenticated();
    //   this.setState({ ready: true, authenticated });
    // }


    componentDidMount() {
        new ApiService().get("/_configuration/isauth")
            .then(authenticated => this.setState({ready: true, authenticated}))
    }


    render() {

        const href = window.location.href;

        // required for single player page and landing page with video
        if (href.includes('guest')) {
            return (
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, requireAuth, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                </Routes>
            );
        }

        if (!href.includes('guest') && !href.includes('app/hubspot/install')) {

            if (!this.state.ready) // Waiting for check if auth or not
                return <div className='spinner-center'><Spinner /></div>

            if (!this.state.authenticated) {
                const suffix = window.location.pathname;
                if (suffix !== '/' && suffix.indexOf('i/' < 0)) {
                    // if(suffix.length > 4 && suffix.length < 10){
                    window.location.href = 'i' + suffix;
                    return <div className='spinner-center'><Spinner /></div>
                }
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    window.location.href = "Identity/Account/login";
                } else if (window.location.href.startsWith("https://weq.se/guest-view?url=")) {
                    // If the URL matches the specific format, do not redirect
                } else {
                    window.location.href = "https://weqonect.com";
                }
                return <div className='spinner-center'><Spinner /></div>
            }
        }
        const auth = this.state.authenticated;
        return (            
            <Layout auth={auth}>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const {element, requireAuth, ...rest} = route;
                        return <Route key={index} {...rest} element={element}/>;
                        // return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
                    })}
                    <Route path='*' element={<RedirectAsd/>}/>
                </Routes>
            </Layout>
        );
    }
}

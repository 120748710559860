import React, { useEffect, useState, useRef } from 'react';
import { Form, Input, InputGroup, Button, Label } from 'reactstrap';
import { ApiService } from '../services/apiService';

export const CaptureScreen = (props) => {
    const [page, setPage] = useState({});
    const [imageType, setImageType] = useState('image/png');
    const [imageData, setImageData] = useState('');
    const [loading, setLoading] = useState(false);
    const formRef = useRef();

    useEffect(() => {
       
    }, [imageData])

    const ImageTag = ({ data, type }) => <img src={`data:${type};base64,${data}`} width='800px' height='600px' alt='capture screen' />

    const handleSubmit = (event) => {
        event.preventDefault();
        new ApiService().get(`utility/screen/capture?webDomain=${page.webDomain}`)
            .then(res => {
                if (res && res.results && res.results.length > 0) {
                    const first = res.results[0];
                    setImageData(first.imageBase64);
                    setImageType(first.imageType);
                    console.log(first);
                }
            })
            .catch(() => alert('Something went wrong'))
            .finally(() => { });
    }
    const set = (input, e) => {
        setPage(o => ({ ...o, [input]: e.target.value }));
    }
    return (
        <div style={{
            backgroundColor: 'white',
            padding: '1em',
            borderRadius: '5px',
        }}>
            <h4 className='text-center text-primary'>Screen Capture</h4>
            <Form ref={formRef} onSubmit={handleSubmit}>
               
                <InputGroup>
                    <Label>Web domain
                        <Input type="text" key="webDomain" placeholder='Web domain' onChange={e => set('webDomain', e)} />
                    </Label>
                </InputGroup>
                <InputGroup>
                    <Label>Wait for capture (in seconds)
                        <Input type="number" key="waitCapture" placeholder='Wait for capture in seconds' onChange={e => set('waitCapture', e)} />
                    </Label>
                </InputGroup>
                <InputGroup>
                    <Label>Capture image height (in pixels)
                        <Input type="number" key="captureHeight" placeholder='Capture image height (in pixels)' onChange={e => set('captureHeight', e)} />
                    </Label>
                </InputGroup>
                <InputGroup>
                    <Label>Capture image width (in pixels)
                        <Input type="number" key="captureWidth" placeholder='Capture image width (in pixels)' onChange={e => set('captureWidth', e)} />
                    </Label>
                </InputGroup>
                <InputGroup>
                    <Label>
                        <input type="radio" name="captureOption" placeholder='Capture web page' value='webpage' onChange={e => set('captureWidth', e)} />
                        &nbsp;&nbsp;Capture Web page
                    </Label>
                </InputGroup>
                <InputGroup>
                    <Label>
                        <input type="radio" name="captureOption" placeholder='Capture logo' value='logo' onChange={e => set('captureWidth', e)} />
                        &nbsp;&nbsp;Capture logo
                    </Label>
                </InputGroup>
                <Button className='mt-3' color="primary" type="submit">Submit</Button>
            </Form>
            <div id="container"><ImageTag data={imageData} type={imageType} /></div>
        </div>        
    );
}
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormText, Input, InputGroup, InputGroupText, Row, Spinner } from 'reactstrap';
import { ApiService } from '../services/apiService';

export const CreateLandingPage = (props) => {
    const [page, setPage] = useState({});
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [logoPreview, setLogoPreview] = useState(null); // State to manage logo preview

    useEffect(() => {
        if (props.project) {
            setLoading(true);
            new ApiService().get('landingpage/' + props.project.id)
                .then(r => {
                    if (r?.id) {
                        setPage(r);
                        if (r.logoUrl) {
                            setLogoPreview(r.logoUrl); // Set the existing logoUrl as the preview if it exists
                        }
                    }
                })
                .catch(() => alert('Something went wrong'))
                .finally(() => setLoading(false));

            let editColumns = [...props.project.columns];
            editColumns.sort((a, b) => a.index - b.index);
            setColumns(editColumns.map(c => ({ name: c.name, defaultValue: c.defaultValue })));
        }
    }, [props.project]);

    const handleFileInput = (event, field) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result); // Set the logo preview (base64)
                setPage(o => ({ ...o, [field.key]: reader.result })); // Store the base64 image in the page object
            };
            reader.readAsDataURL(file);
        } else {
            setLogoPreview(null); // Reset the preview if no file is selected
        }
    };

    const handleRemoveImage = () => {
        setLogoPreview(null); // Remove the preview
        setPage(o => ({ ...o, logoUrl: null })); // Clear the logoUrl in the page object

        // Reset the file input to allow re-uploading the same image if desired
        const fileInput = document.getElementById('logoFileInput');
        if (fileInput) {
            fileInput.value = ''; // Clear the file input value
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        // Determine which logo to send (existing logoUrl or new logoPreview)
        const logoData = logoPreview ? logoPreview : page.logoUrl;

        // Include the selected image data in the form submission
        new ApiService().post('landingpage', {
            ...page,
            projectId: props.project.id
        })
            .then(r => r?.id && setPage(r))
            .catch(() => alert('Something went wrong'))
            .finally(() => setLoading(false));
    };

    const set = (input, e) => {
        let val = input.type?.startsWith('check') ? e.target.checked : input.type?.startsWith('enum') ? parseInt(e.target.value) : e.target.value;
        setPage(o => ({ ...o, [input.key]: val }));
    };

    const minHeight = () => {
        let height = 30;
        if (!page.message)
            return height;
        const split = page.message?.split('\n');
        let rows = split.length;
        split.forEach(s => rows += Math.floor(s.length / 30))
        height += 21 * (rows)
        return height + 'px'
    };

    const inputs = [
        { key: 'active', text: 'Activated', type: 'checkbox' },
        {
            key: 'layout', text: 'Layout', type: 'enum', options: [
                { id: 1, name: '16:9' },
                { id: 2, name: '4:3' },
                { id: 3, name: '3:2' },
                { id: 4, name: '1:1' }
            ],
            optional: true
        },
        { key: 'embed1', text: 'iFrame Url 1', optional: true },
        { key: 'embed2', text: 'iFrame Url 2', optional: true },
        { key: 'logoUrl', text: 'Logo url', optional: true },
        { key: 'backgroundUrl', text: 'Background Url', optional: true },
        { key: 'primaryColor', text: 'Background Color', type: 'color' },
        { key: 'secondaryColor', text: 'Text Color', type: 'color' },
        { key: 'textCtaButton', text: 'Text CTA-Button', optional: true },
        { key: 'ctaButtonAction', text: 'CTA-Button Action', optional: true },
        { key: 'ctaButtonColor', text: 'CTA-Button Color', type: 'color', optional: true },
    ];

    if (loading)
        return <div className='spinner-center'><Spinner /></div>

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <FormText>Landing Page</FormText>
                    {inputs.map((v, i) =>
                        <div key={i}>
                            <InputGroup className="mb-2">
                                <InputGroupText>{v.text}</InputGroupText>

                                {
                                    v.key === 'logoUrl' ?
                                        <Input
                                            className={v.class || ''}
                                            type="file"
                                            onChange={(e) => handleFileInput(e, v)}
                                            style={{ flexGrow: 1, marginRight: '8px' }}
                                            id="logoFileInput"
                                        /> :
                                        v.type === 'enum' ?
                                            <Input required={!v.optional} className={v.class || ''}
                                                type={'select'} value={page[v.key] ?? ''}
                                                onChange={(e) => set(v, e)}>
                                                <option value={''}>Select</option>
                                                {v.options.map((o, i) => <option key={o.id} value={o.id}>{o.name}</option>)}
                                            </Input> :
                                            <Input style={{ height: 'inherit' }} required={v.type !== 'checkbox' && !v.optional}
                                                className={v.class || ''} type={v.type ?? 'text'}
                                                placeholder={v.placeholder ?? v.text} value={page[v.key] ?? ''}
                                                checked={page[v.key] ?? false} onChange={(e) => set(v, e)} />
                                }
                            </InputGroup>
                            {/* Display the logo preview if it exists under Logo URL */}
                            {v.key === 'logoUrl' && logoPreview && (
                                <div style={{ position: 'relative', display: 'inline-block', marginBottom: '16px' }}>
                                    <button
                                        style={{
                                            position: 'absolute',
                                            top: '10px',
                                            right: '10px',
                                            backgroundColor: 'red',
                                            color: 'white',
                                            border: 'none',
                                            borderRadius: '50%',
                                            width: '24px',
                                            height: '24px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={handleRemoveImage} // Clear the preview and reset the input on click
                                    >
                                        X
                                    </button>
                                    <div style={{
                                        border: '1px solid #ddd',
                                        padding: '10px',
                                        maxWidth: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '4px'
                                    }}>
                                        <img src={logoPreview} alt="Logo Preview" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <InputGroup className="mb-2 smartphone">
                                <Input required id="smartphone-textarea" type={'textarea'} placeholder={'Message'}
                                    value={page['message'] ?? ''}
                                    onChange={(e) => set({ key: 'message', type: 'textarea' }, e)}
                                    style={{ minHeight: minHeight() }}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <b>Keys</b>
                            {[{ name: 'VideoLink' }, ...columns].map((c, i) =>
                                c?.name &&
                                <div key={i} style={{ width: 'max-content' }}>
                                    <span className='clickable icofont me-2'
                                        onClick={() => navigator.clipboard.writeText('{' + c.name + '}')}>&#xec51;</span>
                                    <span className='clickable'
                                        onClick={() => {
                                            setPage(o => ({ ...o, message: (o.message ?? '') + '{' + c.name + '}' }));
                                            document.getElementById('smartphone-textarea').focus();
                                        }}>
                                        {'{' + c.name + '}'}
                                    </span>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={3}></Col>
                <Col>
                    <Button color="secondary" className='w-100 mt-3 mb-3'
                        type="submit">{props.project ? 'Save Changes' : 'Create Project'}</Button>
                </Col>
                <Col xs={3}></Col>
            </Row>
        </Form>
    );
}

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {Button, InputGroup, Spinner} from 'reactstrap';
import {ApiService} from '../services/apiService';
import {exportObjectsToCSV} from '../services/utility';
import {Stats} from "../components/Stats";

export const Home = () => {
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(0);
    const navigate = useNavigate();
    const forceFindVideos = () => {
        setLoading(true)
        setDisabled(70)
        new ApiService().get('utility/findvideos').then().finally(() => setLoading(false));
        let handler = setInterval(() => setDisabled(o => {
            if (o < 2) {
                clearInterval(handler)
                return 0;
            }
            return o - 1
        }), 1000); // To avoid excessive use (can result in external party blocks the app)
    }
    const forceHubSpotSync = () => {
        setLoading(true)
        setDisabled(0)
        new ApiService().get('public/hubspot-sync').then(r => {
            setLoading(false)
            setDisabled(0)
        });
    }
    const clearStats = () => {
        if (!window.confirm('Are you sure you want to DELETE all stats?'))
            return;
        new ApiService().get('public/clear-rowformstats').then()
    }
    const exportOrgStats = () => {
        new ApiService().get('public/rowformstats').then(r => {
            const org = r?.perOrganisations?.find(o => o.organisation === 'bildeve');
            org?.perPersons?.forEach(p => {
                p.Phone = p.phone;
                delete p.phone;
            })
            org ? exportObjectsToCSV(org.perPersons, 'bildevestats.csv') : alert('No stats at this moment.')
        })
    }
    const download = () => {
        new ApiService().getBlob('utility/download-files').then(r => {
            let csvFile;
            let downloadLink;
            csvFile = new Blob([r], {type: 'application/octet-stream'});
            downloadLink = document.createElement("a");
            downloadLink.download = "files.zip";
            downloadLink.href = window.URL.createObjectURL(csvFile);
            downloadLink.click();
        });
    }
    const links = [
        {name: 'Instruction Video', link: 'https://bit.ly/3BA06F6 '},
        {name: 'Bulk Gate', link: 'https://portal.bulkgate.com/sign/in'},
        {name: 'Vimeo', link: 'https://vimeo.com/log_in'},
        {name: 'Org Stats', link: exportOrgStats, cross: clearStats},
        {name: 'Download Files', link: download},
    ];
    const btnStyle = {
        marginRight: '1rem',
        marginTop: '0.5rem',
        marginBottom: '2rem',
        borderRadius: '5px',
        fontWeight: 'bold',
        fontSize: '1.2rem',
        padding: '1rem',
        width: '23rem',
        textTransform: 'uppercase',
    }
    const forceSend = () => {
        setLoading(true)
        setDisabled(70)
        new ApiService().get('utility/sendout').then().finally(() => setLoading(false));
        let handler = setInterval(() => setDisabled(o => {
            if (o < 2) {
                clearInterval(handler)
                return 0;
            }
            return o - 1
        }), 1000); // To avoid excessive use (can result in external party blocks the app)
    }
    const goto = (link) => {
        if (typeof link === 'function') {
            link();
        } else {
            const a = document.createElement('a');
            a.href = link;
            a.target = '_blank';
            a.click();
        }
    }
    return (
        <>
            <h4 style={{marginBottom: '2rem', fontSize: '30px'}}>Home</h4>
            <h6 style={{fontSize: "20px", marginBottom: 0}}><b>SMART LINKS</b></h6>
            <div className="d-flex justify-content-between">
                <div>
                    <div style={{
                        marginBottom: '3rem',
                        display: 'grid',
                        gridTemplateColumns: '30rem 30rem',
                        height: 'fit-content'
                    }}>
                        {links.map((l, i) =>
                            <InputGroup key={i}>
                                <Button color='primary' style={{...btnStyle}} onClick={() => goto(l.link)}><span
                                    style={{color: '#FDBD00'}}>{l.name.split(' ')[0]}</span>&nbsp;
                                    <span>{l.name.split(' ').slice(1).join(' ')}</span></Button>
                                {l.cross && <Button color='danger icofont' style={{
                                    borderRadius: '1rem',
                                    fontSize: '0.5rem',
                                    padding: 0,
                                    width: '2rem',
                                    height: '2rem',
                                    marginTop: '1rem'
                                }} onClick={l.cross}>&#xec4f;</Button>}
                            </InputGroup>
                        )}
                    </div>

                    <div>
                        <h6 style={{fontSize: "20px", marginBottom: 0}}><b>MANUAL
                            TRIGGERS {disabled > 0 ? ('(' + disabled + ')') : ''}</b></h6>
                        {loading ?
                            <Spinner></Spinner>
                            :
                            <div style={{ display: 'grid', gridTemplateColumns: '30rem 30rem' }}>                               
                                <div>
                                    <Button color='primary' disabled={disabled > 0} style={{...btnStyle}}
                                            onClick={forceFindVideos}>Find
                                        Videos</Button>
                                    <small style={{ color: '', fontSize: 'small', display: 'block', fontWeight: 'bold' }}>Go through all rows
                                        and set
                                        up video links if the video exists.</small>
                                    <small className='' style={{ color: 'red', fontSize: 'small', display: 'block', fontWeight: 'bold' }}>Warning:
                                        Use sparingly, Vimeo Pus: Max 125 api requests / minute.</small>
                                </div>
                                <div style={{display: 'inline-block'}}>
                                    <Button color='primary' disabled={disabled > 0} style={{...btnStyle}}
                                            onClick={forceSend}>Send
                                        SMS and Emails</Button>
                                    <small style={{ color: '', fontSize: 'small', display: 'block' , fontWeight: 'bold' }}>Go through all rows
                                        and send
                                        out sms and email.</small>
                                    <small className='' style={{color: 'red', fontSize: 'small', display: 'block', fontWeight: 'bold'}}>Warning:
                                        Use sparingly</small>
                                </div>
                                <div>
                                    <Button color='primary' disabled={disabled > 0} style={{ ...btnStyle }}
                                        onClick={forceHubSpotSync}>HubSpot Sync</Button>
                                    <small style={{ color: '', fontSize: 'small', display: 'block', fontWeight: 'bold' }}>Go through all the hubspot projects and rows
                                        and update status and clicks.</small>
                                </div>
                         
                            </div>
                        }
                    </div>
                </div>

                <Stats/>
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginTop: '2em',
            }}>
                <img width="150px" src="cx_logo.png" alt="logo"
                     style={{
                         background: 'white',
                         opacity: '0.9',
                         borderRadius: '8px',
                         padding: '10px',
                         display: 'none'
                     }}
                />
            </div>
        </>
    );
}
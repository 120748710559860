import './public.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Col, Row, Spinner, Toast, ToastBody } from 'reactstrap';
import { ApiService } from '../../services/apiService';
import { DefaultRowForm } from './custom-row-forms/DefaultRowForm';
import { BildeveRowForm } from './custom-row-forms/BildeveRowForm';

export const RowForm = (props) => {
  const [template, setTemplate] = useState();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [toastOpacity, setToastOpacity] = useState(0);
  const [initialized, setInitialized] = useState(false);
  const formRef = useRef();
  const { id } = useParams();
  let [params] = useSearchParams();

  const set = (input, e, format = (a => a)) => {
    setValues(o => ({ ...o, [input]: format(e.target.value) }))
  }
  const setMany = useCallback((obj) => setValues(o => ({...o, ...obj})), [setValues]);

  useEffect(() => {
    setLoading(true);
    new ApiService().get('public/form/' + id)
      .then(r => r.columns && setTemplate(r))
      .finally(() => setLoading(false));
  }, [id])

  const handleSubmit = (event) => {
    event.preventDefault();
    // if (values['SendDate'] || window.confirm('You never specified a send date. Project default send date will be used. Are you sure?')) {
    setLoading(true);
    let arr = []; // array of [key, value] in BE parsed as KeyValuePair[]
    Object.keys(values).forEach(k => arr.push([k, values[k]]));
    const send = { secret: id, values: arr, organisation: params.get('org') };
    new ApiService().post('public/form/' + id, send)
      .then(r => {
        if (r) {
          setValues({});
          setInitialized(true);
          setToastOpacity(1)
          // fade out
          let h = setInterval(() => {
            setToastOpacity(o => {
              if (o < 0)
                clearInterval(h);
              return o - 0.025;
            });
          }, 60);
        }
      })
      .catch(() => alert("Something went wrong"))
      .finally(() => setLoading(false));
    // }
  }

  const CustomRowFormHtml = () => {
    const poppies = { formRef, handleSubmit, setMany, set, template, values, key: 'custom-form' }
    if (params.get('org') === 'bildeve')
      return <BildeveRowForm {...poppies} />

    return <DefaultRowForm {...poppies} />
  }

  if (loading)
    return <div className='background' style={template?.backgroundUrl ? { backgroundImage: `url(${template.backgroundUrl})` } : {}}><div className='spinner-center'><Spinner /></div></div>

  if (!template)
    return <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>ARE YOU LOST?</div>

  return (
    <div className='background' style={template?.backgroundUrl ? { backgroundImage: `url(${template.backgroundUrl})` } : {}}>
      <Row className={initialized ? 'rowform' : 'rowform fade-in'}>
        <Col style={{padding: 0}}>
          {CustomRowFormHtml()}
          <Toast style={{ maxWidth: '90vw', position: 'fixed', bottom: '0.5rem', left: '50vw', opacity: toastOpacity, pointerEvents: 'none', transform: 'translateX(-50%)' }}
            className="p-1 bg-success text-light">
            <ToastBody>
              Successfully Added!
            </ToastBody>
          </Toast>
        </Col>
      </Row>
    </div>
  );
}
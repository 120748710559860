import React, { useEffect, useState } from 'react';
import { Badge, Button, Input, InputGroup, Spinner, Table } from 'reactstrap';
import { EditLink } from '../components/EditLink';
import { InfoModal } from '../components/InfoModal';
import { useHandleModal } from '../hooks/useHandleModal';
import { ApiService } from '../services/apiService';
import { colorize, niceDate } from '../services/utility';
import ExportQR from '../components/ExportQR';
import { Links } from './Links';

export const LinkCategories = () => {
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([]);
  const [newName, setNewName] = useState('');
  useEffect(() => {
    setLoading(true)
    refetch()
  }, []);
  const refetch = () => {
    new ApiService().get('l/categories').then(l => l && setLinks(l))
      .finally(() => setLoading(false));
  }

  const add = () => {
    if ((newName?.length ?? 0) < 3)
      return;
    setLoading(true)
    new ApiService().post('l/categories', { name: newName }).then(l => {
      if (l?.id) {
        setNewName('')
        setLinks(o => [l, ...o])
      }
    }).finally(() => setLoading(false));
  }

  const remove = (id) => {
    if (!window.confirm('Are you sure you want to delete the category?'))
      return;
    setLoading(true)
    new ApiService().delete('l/categories/' + id)
      .catch(() => alert('Something went wrong'))
      .then(l => {
        if (l) setLinks(o => o.filter(oo => oo.id !== id))
        else alert('Something went wrong')
      }).finally(() => setLoading(false));
  }
  const edit = (r) => {
    modal.setItem(r);
    modal.showModal();
  }
  const modal = useHandleModal({ onClose: (needRefetch) => needRefetch && refetch() });
  const bg = (i) => i%2? '#e9e9e9' : 'white'
  return (
      <div
          style={{
              backgroundColor: 'white',
              padding: '1em',
              borderRadius: '5px',
          }}>
      <h4 style={{ marginBottom: '2rem' }}>Links</h4>
      <div style={{ float: 'right', margin: '1rem' }}>
        <InputGroup>
          <Input id='namename' style={{ width: '20rem' }} onKeyDown={(e) => e.key === 'Enter' && add()} value={newName} onChange={(e) => setNewName(e.target.value)} placeholder='Category..' />
          <Button color='outline-primary' onClick={add} className='icofont' style={{ width: '5rem' }}>&#xec3e;</Button>
        </InputGroup>
      </div>
      {loading ?
        <Spinner></Spinner>
        :
        <Table style={{ backgroundColor: 'white', color: '#F39200' }}>
          <thead>
            <tr>
              <th>Name</th>
              <th className='text-center' style={{width: '6rem'}}>Action</th>
            </tr>
          </thead>
          <tbody>
            {links?.map((r, i) =>
              <React.Fragment key={r.id}>
                <tr className='tr-td-vc' style={{ backgroundColor: bg(i) }}>
                  <td className='clickable' style={{borderBottomWidth: r.open? 0 : 1}} onClick={() => setLinks(ls => ls.map(ll => ll.id === r.id ? { ...ll, open: !!!ll.open } : ll))}><b>{r.name}</b></td>
                  <td className="text-center" style={{borderBottomWidth: r.open? 0 : 1}}>
                    {/* <span className='clickable icofont mx-1' onClick={() => edit(r)}>&#xec55;</span> */}
                    <span className='clickable icofont mx-1' onClick={() => remove(r.id)}>&#xee09;</span>
                    {/* <ExportQR url={r.urlShort} name={r.name} /> */}
                  </td>
                </tr>
                {r.open &&
                  <tr key={r.name} style={{ backgroundColor: bg(i) }}>
                    <td colSpan={2} style={{padding: '2rem', paddingBottom: '4rem'}}><Links categoryId={r.id} /></td>
                  </tr>
                }
              </React.Fragment>
            )}
          </tbody>
        </Table>
      }
      {/* <InfoModal
        handleClose={modal.closeModal}
        show={modal.show}
        headerText={modal.item?.urlShort}
        body={<EditLink row={modal.item} success={() => { modal.closeModal(); refetch(); }} />}
      /> */}
    </div>
  );
}
import React, { useRef, useState } from 'react';
import { Form, Input, InputGroup, Col, Row, Button, Label, Spinner, InputGroupText } from 'reactstrap';
import { ApiService } from '../services/apiService';
import RowForm from './RowForm';

export const FillProject = (props) => {
	const project = props.project;
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState();

	const handleSubmitFile = (event) => {
		event.preventDefault();
		setLoading(true);
		new ApiService().postWithFiles(`project/${project.id}/rows`, { file })
			.catch(() => alert("Something went wrong"))
			.then(r => r && props?.refetchProject(true))
			.finally(() => setLoading(false));
	}

	if (loading)
		return <div className='spinner-center'><Spinner /></div>


	if (!props.multi) {
		return (
			<Row>
			   <Col>
				   <h5>Append Single Row</h5>
				   <RowForm 
					   project={project} 
					   onSuccess={() => {
						   alert('Row added successfully');
					   }} 
				   />
			   </Col>
		   </Row>
		   )
	}
	return (
		<Row>
			<Col>
				<h5>Upload CSV File</h5>
				<ul>
					<li>Column Order is not important</li>					
					<li>All columns not required. Default value will be used if not present.</li>
					<li>File format: UTF8</li>
					<li>Values will override the Column Default value if specified</li>
					<li>Commas (,) and Semicolons (;) may not be used as values (Only use them as delimiters). If you need , or ; write COMMA or SEMICOLON instead</li>
					<li>Required Columns: sendDate, one of phone, email or recordId</li>
					<li>Optional Columns: {project?.columns.filter(c => c.name !== 'output').map(c => c.name).join(',')}</li>
				</ul>
				<p>Example file.csv <small><small><small><small><b>(Important: cAsE SenSItiVe headers must match case of project headers!)</b></small></small></small></small></p>
				<pre>{
					`phone,sendDate,firstName,lastName,recordId
46733235223,2022-09-27T12:34:00,Peter,Niklasson,1`
				}</pre>
				<Form onSubmit={handleSubmitFile}>
					<InputGroup>
						<Label>Select file:
							<Input
								type="file"
								onChange={(e) => setFile(e.target.files[0])}
							/>
						</Label>
					</InputGroup>
					<Button className='mt-3' color="primary" type="submit">Upload and Process</Button>
				</Form>
			</Col>
		</Row>
	);
}
import './DefaultRowForm.scss';
import React, { useState } from 'react';
import { Form, Input, InputGroup, Button, Label } from 'reactstrap';
import { capitalizeWords } from '../../../services/utility';

export const DefaultRowForm = ({ template, values, set, handleSubmit, formRef }) => {
    const [showDefaults] = useState(false);
    const [phoneRequired, setPhoneRequired] = useState({ });
    const [emailRequired, setEmailRequired] = useState({ });
    const [recordRequired, setRecordRequired] = useState({ });
  
  return (
    <div className='DefaultRowForm'>
      <img width="150px" height="auto" className="img-responsive" src="logo.png" alt="logo" />
      <h4 className='text-center text-primary'>{template?.title}</h4>
      <Form ref={formRef} onSubmit={handleSubmit}>
        {/* <InputGroup>
              <Label>SendDate
                <Input type='datetime-local'
                  required
                  defaultValue={values['SendDate'] ?? template?.sendDate}
                  onChange={e => set('SendDate', e)} />
              </Label>
            </InputGroup> */}
        <InputGroup>
          <Label>Phone
            <Input defaultValue={values['Phone']} {...phoneRequired} type="tel" placeholder='Phone' onChange={e => set('Phone', e)} />
          </Label>
        </InputGroup>
        <InputGroup>
          <Label>Email
            <Input defaultValue={values['Email']} {...emailRequired} type="email" placeholder='Email' onChange={e => set('Email', e)} />
          </Label>
        </InputGroup>
        {template?.columns.map((c, i) =>
          ((!c.defaultValue || values[c.name]) || showDefaults) &&
          <InputGroup key={i} style={{ maxWidth: '40rem' }} className='mb-1'>
            <Label>{capitalizeWords(c.name.replace('_', ' '))}
              <Input required disabled={c.name === 'output'} placeholder={capitalizeWords(c.name.replace('_', ' '))} defaultValue={values[c.name] ?? c.defaultValue}
                onChange={(e) => set(c.name, e)} />
            </Label>
          </InputGroup>
        )}
        <Button className='mt-3' color="primary" type="submit">Submit</Button>
      </Form>
    </div>
  );
}
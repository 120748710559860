import React, {Component} from 'react';
import {Container} from 'reactstrap';
import {NavMenu} from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div style={{
                minHeight: '100vh',
                paddingBottom: '2rem',
                background: 'url("bg4.png")',
                zIndex: 1,
                //filter: 'sepia(1%)',
                backgroundBlendMode: 'multiply',
                backgroundColor: 'rgba(253, 189, 0, 0)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
            >
                <NavMenu auth={this.props.auth} />
                <Container style={{ overflow: 'auto' }}>
                    {this.props.children}
                </Container>
            </div>
        );
    }
}

import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Col, Form, FormText, Input, InputGroup, InputGroupText, Row, Spinner} from 'reactstrap';
import {ApiService} from '../services/apiService';
import { localeDate, utcDate } from '../services/utility';

export const CreateProject = (props) => {
    const [project, setProject] = useState({});
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [fileInputKey, setFileInputKey] = useState(Date.now());
    const [columns, setColumns] = useState([
        {name: 'render-status', defaultValue: 'ready'},
        {name: 'output', defaultValue: '*Random*'},
        {name: 'module', defaultValue: '1080 HQ Templater'},
        {name: 'render-settings', defaultValue: 'Best Settings'},
    ]);
    const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const navigate = useNavigate();
    useEffect(() => {
        if (props.edit) {
            if (props?.edit['sendDate']) props.edit['sendDate'] = localeDate(props.edit['sendDate']);
            setProject({id: props.edit.id, message: props.edit.message});
            inputs.forEach(i => setProject(o => ({...o, [i.key]: props.edit[i.key]})));
            let editColumns = [...props.edit.columns];
            editColumns.sort((a, b) => a.index - b.index);
            setColumns(editColumns.map(c => ({ name: c.name, defaultValue: c.defaultValue })));
            if (props.edit.imageUrl) {
                setImageUrl(props.edit.imageUrl);
            }
        }
    }, [props.edit])

    const handleSubmit = (event) => {
        event.preventDefault();

        // Validation checks
        if (!columns.find(c => c.name === 'output')) {
            alert('Column "output" doesn�t exist.')
            return;
        } else if (columns.length !== [...new Set(columns.map(c => c.name))].length) {
            alert('There are duplicate column names.')
            return;
        }

        if (project['sendDate']) project['sendDate'] = utcDate(project['sendDate']);

        setLoading(true);
        const columnsWithIndex = columns.map((c, i) => ({ ...c, index: i }));
        const payload = {
            ...project,
            columns: columnsWithIndex,
            imageUrl: imageUrl
        };
        // Check if imageBase64 exists and add it to the payload
        if (project.imageBase64) {
            payload.imageBase64 = project.imageBase64;
        }

        // Confirm before updating if there's a fixed video link
        if (props.edit && project.fixedVideoLink && !window.confirm('You have a Fixed Video Link. This will cause all existing unsent rows to be updated with this link. Are you sure?')) {
            setLoading(false)
            return;
        }

        // Consolidated API call
        const apiService = new ApiService();
        const apiCall = props.edit ? apiService.put : apiService.post;
        const endpoint = 'project';

        apiCall(endpoint, payload)
            .then(p => {
                if (p?.id) {
                    props.edit ? props.refetchProject(true) : navigate('/pjs/' + p.id);
                }
            })
            .catch(() => alert('Something went wrong'))
            .finally(() => setLoading(false));
    }

    const set = (input, e) => {
        let val = input.type?.startsWith('check') ? e.target.checked : e.target.value;
        if (input.key === 'sheetId') {
            //Pick the ID from hettps://docs.goodle.com/spreadsheets/d/1PWmtWX4BOP_b9_odP2L4Vv4jg2vBhLVgpa3E7G0UU_w
            val = val.split('/').find(s => s.length > 40) ?? val;
        }
        setProject(o => ({...o, [input.key]: val}));
    }
    const setVar = (index, e, forKey) => {
        let old = {...columns[index]};
        if (forKey)
            old.name = e.target.value.replaceAll(',', '').replaceAll(';', '');
        else
            old.defaultValue = e.target.value;
        setColumns(o => o.map((v, i) => i !== index ? v : old))
    }
    const minHeight = () => {
        let height = 30;
        if (!project.message)
            return height;
        const split = project.message?.split('\n');
        let rows = split.length;
        split.forEach(s => rows += Math.floor(s.length / 30))
        height += 21 * (rows)
        return height + 'px'
    }
    const inputs = [
        {key: 'name', text: 'Name'},
        {key: 'sendSms', text: 'Send SMS', type: 'checkbox'},
        {key: 'sendEmail', text: 'Send Email', type: 'checkbox' },
        {key: 'sendProvider', text: 'Send External', type: 'checkbox' },
        {key: 'fromPhone', text: 'Sender Name'},
        {key: 'sendDate', text: 'Default Send Date', type: 'datetime-local'},
        {key: 'sheetId', text: 'Sheet ID'},
        {key: 'videoNameCode', text: 'Video Name Code', placeholder: 'Ex: video_{First_name}'},
        {key: 'externalName', text: 'External Name', placeholder: 'Name for outsiders'},
        {key: 'externalBackgroundUrl', text: 'External Background Url', placeholder: 'Background Url', optional: true},
        {
            key: 'externalNotificationPhone',
            text: 'External Notification Phone',
            placeholder: 'Phone Number',
            optional: true
        },
        { key: 'externalProvider', text: 'External System', placeholder: 'Name of External System', optional: true },
        {key: 'fixedVideoLink', text: 'Fixed Video Link', placeholder: 'Video Link', optional: true},
        // { key: 'message', text: 'Message', type: 'textarea', class: 'smartphone' },
    ];

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProject({ ...project, imageBase64: reader.result });
                setImageUrl(reader.result); // Update the image URL for preview
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = () => {
        setImageUrl(null);
        setProject({ ...project, imageBase64: null });

        setFileInputKey(Date.now());
    };

    const removeButtonStyle = {
        position: 'relative',
        top: '5px',
        right: '5px',
        padding: '2px 5px',
        fontSize: '12px',
        lineHeight: '1',
        borderRadius: '50%',
        color: '#fff',
        backgroundColor: '#dc3545', // Bootstrap's btn-danger color
        border: 'none',
        cursor: 'pointer'
    };

    if (loading)
        return <div className='spinner-center'><Spinner/></div>
    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <FormText>New Project</FormText>
                    {inputs.map((v, i) =>
                        <InputGroup key={i} className="mb-2">
                            <InputGroupText>{v.text}</InputGroupText>
                            <Input required={v.type !== 'checkbox' && !v.optional} className={v.class || ''}
                                   type={v.type ?? 'text'}
                                   placeholder={v.placeholder ?? v.text} value={project[v.key] ?? ''}
                                   checked={project[v.key] ?? false} onChange={(e) => set(v, e)}/>
                        </InputGroup>
                    )}

                    <div className={"mt-4"}>
                        {/* pattern="[^,;]*$" */}
                        <FormText>Goodle Sheets Column Headers</FormText><br/>
                        {props.edit &&
                            <FormText><small className='text-danger'>Warning: altering the name of existing columns will
                                cause data loss </small></FormText>}
                        {columns.map((v, i) =>
                            <InputGroup key={i} className="mb-2">
                                <InputGroupText style={{fontFamily: 'monospace'}}>{alphabet[i]}1</InputGroupText>
                                <Input type='text' required value={v.name ?? ''} placeholder='Column'
                                       onChange={(e) => setVar(i, e, true)}/>
                                <Input type='text' value={v.defaultValue ?? ''} placeholder='Default Value'
                                       onChange={(e) => setVar(i, e, false)}/>
                            </InputGroup>
                        )}
                        <InputGroup className="mb-2">
                            <Button color="primary" type='button' onClick={() => setColumns(o => [...o, {}])}>+</Button>
                            <Button color="secondary" type='button'
                                    onClick={() => columns.length && setColumns(o => o.splice(0, o.length - 1))}>-</Button>
                        </InputGroup>
                    </div>

                </Col>
                <Col>
                    <Row className={"mb-3"}>
                        <Col>
                            <InputGroup className="mb-2 smartphone">
                                <Input id="smartphone-textarea" type={'textarea'} placeholder={'Message'}
                                       value={project['message'] ?? ''}
                                       onChange={(e) => set({key: 'message', type: 'textarea'}, e)}
                                       style={{minHeight: minHeight()}}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <b>Keys</b>
                            {[{name: 'VideoLink'}, ...columns].map((c, i) =>
                                    c?.name &&
                                    <div key={i} style={{width: 'max-content'}}>
                                        <span className='clickable icofont me-2'
                                              onClick={() => navigator.clipboard.writeText('{' + c.name + '}')}>&#xec51;</span>
                                        <span className='clickable'
                                              onClick={() => {
                                                  setProject(o => ({
                                                      ...o,
                                                      message: (o.message ?? '') + '{' + c.name + '}'
                                                  }));
                                                  document.getElementById('smartphone-textarea').focus();
                                              }}>
                    {'{' + c.name + '}'}
                  </span>
                                    </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <b>Image</b>
                        <Col>
                            <InputGroup className="mb-2">
                                <InputGroupText>Project Image</InputGroupText>
                                <Input type="file" onChange={(e) => handleImageChange(e)} key={fileInputKey} />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                {imageUrl && (
                                    <button style={removeButtonStyle} onClick={handleRemoveImage}>X</button>
                                )}
                                {imageUrl && (
                                    <div style={{
                                        border: '1px solid #ddd',
                                        padding: '10px',
                                        maxWidth: '60%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '4px'
                                    }}>
                                        <img src={imageUrl} alt="Project" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={3}></Col>
                <Col>
                    <Button color="secondary" className='w-100 mt-3 mb-3'
                            type="submit">{props.edit ? 'Save Changes' : 'Create Project'}</Button>
                </Col>
                <Col xs={3}></Col>
            </Row>
        </Form>
    );
}
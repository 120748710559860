import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import {Home} from "./pages/Home";
import { Projects } from './pages/Projects';
import { Logs } from './pages/Logs';
import {Project} from './pages/Project';
import {NewProject} from './pages/NewProject';
import {RowForm} from './pages/public/RowForm';
import {LandingPage} from './pages/public/LandingPage';
import {LinkCategories} from './pages/LinkCategories';
import { FileUpload } from './pages/public/FileUpload';
import { CaptureScreen } from './pages/CaptureScreen';
import { HubSpotInstall } from './pages/public/HubSpotInstall';
import { PipedriveInstall } from './pages/public/PipedriveInstall';
import VideoPlayer from "./pages/VideoPlayer";
import MultiUpload from "./pages/MultiUpload";

const AppRoutes = [
    {
        index: true,
        requireAuth: true,
        element: <Home/>,
    },
    {
        path: '/new-project',
        requireAuth: true,
        element: <NewProject/>,
    },
    {
        path: '/pjs',
        requireAuth: true,
        element: <Projects/>
    },
    {
        path: '/pjs/:id',
        requireAuth: true,
        element: <Project/>
    },
    {
        path: '/links',
        requireAuth: true,
        element: <LinkCategories/>
    },
    {
        path: '/multi-upload',
        requireAuth: true,
        element: <MultiUpload/>
    },
    {
        path: '/infologs',
        requireAuth: true,
        element: <Logs />
    },
    // Public routes
    {
        path: '/app/hubspot/install',
        requireAuth: false,
        element: <HubSpotInstall />
    },
    {
        path: '/app/pipedrive/install',
        requireAuth: false,
        element: <PipedriveInstall />
    },
    {
        path: '/guest/form/:id',
        requireAuth: false,
        element: <RowForm/>
    },
    {
        path: '/guest/lp/:id',
        requireAuth: false,
        element: <LandingPage/>
    },
    {
        path: '/guest/upload',
        requireAuth: false,
        element: <FileUpload/>
    },
    {
        path: '/i/guest-view',
        requireAuth: false,
        element: <VideoPlayer/>
    },
    {
        path: '/guest-view',
        requireAuth: false,
        element: <VideoPlayer/>
    },  
    {
        path: '/app/capture',
        requireAuth: true,
        element: <CaptureScreen />
    },  
    ...ApiAuthorzationRoutes
];

export default AppRoutes;

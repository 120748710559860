import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Nav, NavItem, NavLink, Spinner, TabContent, TabPane} from 'reactstrap';
import {CreateProject} from '../components/CreateProject';
import {FillProject} from '../components/FillProject';
import {ProjectDetails} from '../components/ProjectDetails';
import {ApiService} from '../services/apiService';
import {CreateLandingPage} from '../components/CreateLandingPage';
import {FilesList} from '../components/FilesList';
import MultiUpload from "./MultiUpload";

export const Project = () => {
    const [project, setProject] = useState();
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {id} = useParams();
    const navs = ['Details', '+ Single Row', '+ Multiple Rows', 'Edit Project', 'Landing Page', 'Files', 'Multi Upload']
    useEffect(() => {
        refetchProject();
    }, [id, setLoading])
    const refetchProject = (redirect) => {
        if (loading)
            return;
        setLoading(true);

        new ApiService().get('project/' + id)
            .then(r => {
                if (r?.id) {
                    setProject(r)
                    redirect && setTab(0);
                }
            })
            .finally(() => setLoading(false));
    }
    return (
        <div
            style={{
                backgroundColor: 'white',
                padding: '1em',
                borderRadius: '5px',
            }}>
            <h4>{project?.name}</h4>
            <a style={{fontSize: '0.65rem', color: 'grey'}} className="icofont" target='_blank' rel='noreferrer'
               href={"https://docs.google.com/spreadsheets/d/" + project?.sheetId}>Sheet
                ID: {project?.sheetId} &#xef1f;</a>
            <br/>
            <br/>
            <Nav tabs>
                <NavItem>
                    <NavLink style={{color: 'grey'}}
                             onClick={() => navigate('/pjs')}>
                        <span className='icofont'>&#xea5c;</span>
                    </NavLink>
                </NavItem>
                {navs.map((n, i) =>
                    <NavItem key={i}>
                        <NavLink
                            className={tab === i ? 'active' : ''}
                            onClick={() => setTab(i)}>
                            {n}
                        </NavLink>
                    </NavItem>
                )}
            </Nav>
            {loading ?
                <div className='spinner-center'><Spinner/></div>
                :
                <TabContent activeTab={tab} className='p-3'>
                    <TabPane tabId={0}>
                        {tab === 0 && <ProjectDetails project={project} refetchProject={refetchProject}/>}
                    </TabPane>
                    <TabPane tabId={1}>
                        {tab === 1 && <FillProject project={project} refetchProject={refetchProject}/>}
                    </TabPane>
                    <TabPane tabId={2}>
                        {tab === 2 && <FillProject multi={true} project={project} refetchProject={refetchProject}/>}
                    </TabPane>
                    <TabPane tabId={3}>
                        {tab === 3 && <CreateProject edit={project} refetchProject={refetchProject}/>}
                    </TabPane>
                    <TabPane tabId={4}>
                        {tab === 4 && <CreateLandingPage project={project} refetchProject={refetchProject}/>}
                    </TabPane>
                    <TabPane tabId={5}>
                        {tab === 5 && <FilesList project={project} refetchProject={refetchProject}/>}
                    </TabPane>
                    <TabPane tabId={6}>
                        {tab === 6 && <MultiUpload project={project} refetchProject={refetchProject}/>}
                    </TabPane>
                </TabContent>
            }
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Spinner } from 'reactstrap';
import { ApiService } from '../services/apiService';
import { updateStateOfItemInArray } from '../services/utility';

export const Projects = () => {
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        new ApiService().get('project').then(r => r && setProjects(r.sort((a, b) => b.id - a.id))).finally(() => setLoading(false));
    }, []);

    const toggleActive = (p) => {
        if (p.locked) return;

        updateStateOfItemInArray({ ...p, locked: true }, setProjects);
        new ApiService().get(`project/setactive?id=${p.id}&active=${!p.active}`)
            .then(r => r && updateStateOfItemInArray({ ...p, active: r.active }, setProjects))
            .catch(() => updateStateOfItemInArray({ ...p, locked: false }, setProjects));
    };

    const generateInviteLink = (p) => {
        if (p.locked) return;

        if (!window.confirm('Are you sure you want to generate a new invite-link? If the project already has an invite-link that one will no longer work.')) return;

        updateStateOfItemInArray({ ...p, locked: true }, setProjects);
        new ApiService().get(`project/generateinvitelink?id=${p.id}`)
            .then(r => r && updateStateOfItemInArray({ ...p, inviteLink: r.inviteLink }, setProjects))
            .catch(() => updateStateOfItemInArray({ ...p, locked: false }, setProjects));
    };

    const processVideos = (p) => {
        if (p.locked) return;

        if (!window.confirm('Are you sure you want to process videos for this project?')) return;
        console.log(p)
        updateStateOfItemInArray({ ...p, locked: true }, setProjects);
        new ApiService().get(`project/sync-rows-with-videos?projectId=${p.id}`)
            .then(r => r && updateStateOfItemInArray({ ...p, locked: false }, setProjects))
            .catch(() => updateStateOfItemInArray({ ...p, locked: false }, setProjects));
    };

    const projectHtml = (p) => {
        return (
            <div key={p.id} style={{ height: '2.5rem' }} className="mb-2">
                <Button style={{ height: '2.5rem', width: '60%', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    color={p.active ? 'outline-primary' : 'outline-secondary'}
                    onClick={() => navigate('/pjs/' + p.id)}>
                    {p.name}
                </Button>
                <div title='Total Clicks' style={{
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    lineHeight: '2.5rem',
                    height: '2.5rem',
                    width: '8%',
                    borderRadius: 0,
                    color: p.active ? '#F39200' : 'inherit',
                    borderTop: '1px solid',
                    borderColor: p.active ? '#F39200' : 'inherit',
                    borderBottom: '1px solid',
                    display: 'inline-block',
                    alignItems: 'center'
                }}>
                    {p.clicks}
                </div>
                <Input title={'Project Status: ' + (p.active ? 'Active' : 'Inactive')} type="checkbox" style={{
                    borderTopLeftRadius: 0,
                    backgroundColor: 'transparent',
                    borderBottomLeftRadius: 0,
                    width: '8%',
                    borderColor: p.active ? '#F39200' : 'inherit',
                    backgroundColor: p.active ? '#F39200' : 'inherit'
                }} checked={p.active} onChange={() => toggleActive(p)} />
                <div className='d-none d-sm-inline'>
                    {p.inviteLink && p.inviteLink !== 'generated' ?
                        <span>
                            <a className='ms-3 me-2' target='_blank' href={'/guest/form/' + p.inviteLink}>
                                <span title='Invitation' className='clickable icofont'>&#xef1f;</span>
                            </a>
                            <span title='Copy' className='clickable icofont me-2'
                                onClick={() => navigator.clipboard.writeText('https://weq.se/guest/form/' + p.inviteLink)}>&#xec51;</span>
                        </span> : <span style={{ opacity: 0.5 }}>
                            <span title='Invitation' className='icofont ms-3 me-2'>&#xef1f;</span>
                            <span title='Copy' className='icofont me-2'>&#xec51;</span>
                        </span>
                    }
                    <span title='Re-generate invite-link' className='clickable icofont me-2'
                        onClick={() => generateInviteLink(p)}>&#xefd1;</span>
                    <span title='Sync rows with videos' className='clickable icofont me-2'
                        onClick={() => processVideos(p)}>&#xec5a;</span> {/* New Process Videos button */}
                </div>
            </div>
        );
    };

    if (loading)
        return <div className='spinner-center'><Spinner /></div>;

    return (
        <div
            style={{
                backgroundColor: 'white',
                padding: '1em',
                borderRadius: '5px',
            }}>
            <h4>Projects</h4>
            <div>
                <Button color='outline-success' className='mb-2 mt-3'
                    onClick={() => navigate('/new-project')}>
                    + New Project
                </Button>
            </div>
            {projects.map(projectHtml)}
        </div>
    );
};

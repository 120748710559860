import React, {useEffect, useState} from 'react';
import {Badge, Button, Input, InputGroup, Spinner, Table} from 'reactstrap';
import {EditLink} from '../components/EditLink';
import {InfoModal} from '../components/InfoModal';
import {useHandleModal} from '../hooks/useHandleModal';
import {ApiService} from '../services/apiService';
import {niceDate} from '../services/utility';
import ExportQR from '../components/ExportQR';

export const Links = (props) => {
    const [loading, setLoading] = useState(false);
    const [links, setLinks] = useState([]);
    const [newLink, setNewLink] = useState('');
    const [newLinkName, setNewLinkName] = useState('');
    const urlurlId = 'urlurl-' + props.categoryId;
    const namenameId = 'namename-' + props.categoryId;

    useEffect(() => {
        setLoading(true)
        refetch()
    }, []);
    const refetch = () => {
        new ApiService().get('l/cat-urls/' + props.categoryId).then(l => l && setLinks(l))
            .finally(() => setLoading(false));
    }

    const add = () => {
        if ((newLink?.length ?? 0) < 5)
            return;
        setLoading(true)
        new ApiService().post('l', {
            url: newLink.startsWith('https://') ? newLink : ('https://' + newLink),
            name: newLinkName,
            categoryId: props.categoryId
        }).then(l => {
            if (l?.id) {
                setNewLink('')
                setNewLinkName('')
                setLinks(o => [l, ...o])
                document.getElementById(urlurlId).focus()
            }
        }).finally(() => setLoading(false));
    }

    const remove = (id) => {
        if (!window.confirm('Are you sure you want to delete the link?'))
            return;
        setLoading(true)
        new ApiService().delete('l/' + id)
            .catch(() => alert('Something went wrong'))
            .then(l => {
                if (l)
                    setLinks(o => o.filter(oo => oo.id !== id))
            }).finally(() => setLoading(false));
    }
    const edit = (r) => {
        modal.setItem(r);
        modal.showModal();
    }
    const modal = useHandleModal({onClose: (needRefetch) => needRefetch && refetch()});

    return (
        <div
            style={{
                backgroundColor: 'white',
                padding: '1em',
                borderRadius: '5px',
            }}>
            <div style={{float: 'right', margin: '1rem'}}>
                <InputGroup>
                    <Input id={urlurlId} autoFocus style={{width: '20rem'}}
                           onKeyDown={(e) => e.key === 'Enter' && document.getElementById(namenameId).focus()}
                           value={newLink} onChange={(e) => setNewLink(e.target.value)} placeholder='https://www...'/>
                    <Input id={namenameId} style={{width: '20rem'}} onKeyDown={(e) => e.key === 'Enter' && add()}
                           value={newLinkName} onChange={(e) => setNewLinkName(e.target.value)} placeholder='Name..'/>
                    <Button color='outline-primary' onClick={add} className='icofont'
                            style={{width: '5rem', backgroundColor: 'black'}}>&#xec3e;</Button>
                </InputGroup>
            </div>
            {loading ?
                <Spinner></Spinner>
                :
                <Table style={{backgroundColor: 'white', color: '#F39200'}}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Short</th>
                        <th className='text-center'>Clicks</th>
                        <th className='text-center'>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {links?.map(r =>
                        <tr key={r.id} className='tr-td-vc'>
                            <td><a rel='noreferrer' href={r.url} target='_blank'>{r.name}</a></td>
                            <td>
                                <span title='Copy' className='clickable icofont me-3'
                                      onClick={() => navigator.clipboard.writeText(r.urlShort)}>&#xec51;</span>
                                <a rel='noreferrer' href={r.urlShort} target='_blank'>{r.urlShort}</a>
                            </td>
                            <td className="fs-5 text-center">
                                {r.clicks ?
                                    <Badge title={niceDate(r.firstClick)} color='secondary'>{r.clicks}</Badge> : ''}
                            </td>
                            <td className="text-center">
                                <span className='clickable icofont mx-1' onClick={() => edit(r)}>&#xec55;</span>
                                <span className='clickable icofont mx-1' onClick={() => remove(r.id)}>&#xee09;</span>
                                <ExportQR url={r.urlShort} name={r.name}/>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            }
            <InfoModal
                handleClose={modal.closeModal}
                show={modal.show}
                headerText={modal.item?.urlShort}
                body={<EditLink row={modal.item} success={() => {
                    modal.closeModal();
                    refetch();
                }}/>}
            />
        </div>
    );
}